let eventGuid = 0;
let todayStr = new Date().toISOString().replace(/T.*$/, ""); // YYYY-MM-DD of today
export const INITIAL_EVENTS = [
   {
      id: createEventId(),
      title: "All-day event",
      start: todayStr,
   },
   {
      id: createEventId(),
      title: "Timed event",
      start: todayStr + "T13:00:00+06:30",
      className: "bg-warning",
   },
   {
      id: createEventId(),
      title: "Meeting with Mr. Shreyu",
      start: new Date(Date.now() + 158000000),
      end: new Date(Date.now() + 338000000),
      className: "bg-warning",
   },
];

export function createEventId() {
   return String(eventGuid++);
}
