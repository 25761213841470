<template>
   <!-- Start Content-->
   <div class="container-fluid">
      <!-- start page title -->
      <div class="row">
         <div class="col-12 text-center">
            <div class="page-title-box">
               <!-- <div class="page-title-right">
                  <ol class="breadcrumb m-0">
                     <li class="breadcrumb-item">
                        <a href="javascript: void(0);">UBold</a>
                     </li>
                     <li class="breadcrumb-item">
                        <a href="javascript: void(0);">Apps</a>
                     </li>
                     <li class="breadcrumb-item active">Calendar</li>
                  </ol>
               </div> -->
               <div class="page-title-right" title="Logout">

                  <router-link class="btn text-white" title="Book Meeting" :to="{ name: 'meeting-create' }">
                      <i class="mdi mdi-calendar-plus me-1"></i>
                  </router-link>
                  <span class="text-white">
                     |
                  </span>
                  <span class="btn text-white">
                     <a><i class="fe-log-out" @click="logout"></i></a>
                  </span>
               </div>
               <h4 class="page-title text-white">CID Meeting App</h4>
            </div>
         </div>
      </div>
      <!-- end page title -->

      <div class="row">
         <div class="col-12">
            <div class="card">
               <div class="card-body">
                  <div class="row">
                     <div class="col-lg-2 mb-2">
                        <select
                           class="form-control custom-select"
                           v-model="selected.roomId"
                           id="meeting-room"
                           @change="filterMeeting()"
                        >
                           <option value="all">All</option>
                           <option
                              v-for="room in rooms"
                              :key="room.id"
                              :value="room.id"
                           >
                              {{ room.name }}
                           </option>
                        </select>
                     </div>

                     <div class="col-lg-2 mb-2">
                        <v-select
                           multiple
                           v-model="selected.intParticipantsId"
                           label="name"
                           :options="intParticipants"
                           :reduce="(name) => name.id"
                           @option:selected="filterMeeting()"
                           @option:deselected="filterMeeting()"
                        ></v-select>
                     </div>

                     <div class="col-lg-8 d-flex justify-content-end">
                        <a
                           href="javascript:void();"
                           @click.prevent="refreshData()"
                           title="Refresh Meeting"
                        >
                           <i class="fe-rotate-cw" v-if="!loading"></i>
                           <span
                              class="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                              v-if="loading"
                           ></span>
                        </a>
                     </div>
                  </div>
                  <div class="row">
                     <place-holder v-if="loading"></place-holder>

                     <transition name="fade">
                        <div class="col-lg-12" v-if="!loading">
                           <FullCalendar
                              :options="calendarOptions"
                           ></FullCalendar>
                        </div>
                     </transition>
                     <!-- end col -->
                  </div>
                  <!-- end row -->
               </div>
               <!-- end card body-->
            </div>
            <!-- end card -->
         </div>
         <!-- end col-12 -->
      </div>
      <!-- end row -->
   </div>
   <!-- container -->
   <MeetingDetailsModal :modalId="'meeting-details'">
      <template v-slot:header>
         {{ meeting.title }}
      </template>

      <template v-slot:body>
         <ul class="list-unstyled mb-0">
            <li>
               <p class="mb-2">
                  <span class="fw-bold me-2">Meeting Date:</span>
                  <span class="text-black">{{ meetingDate }}</span>
               </p>
               <p class="mb-2">
                  <span class="fw-bold me-2">Meeting Room:</span>
                  <span class="text-black">
                     {{ meeting.roomName }}
                  </span>
               </p>
               <p class="mb-2">
                  <span class="fw-bold me-2">Project:</span>
                  <template
                     v-for="(project, index) in meeting.projects"
                     :key="index"
                  >
                     <span class="text-black">{{ project.name }}, </span>
                  </template>
               </p>
               <p class="mb-2">
                  <span class="fw-bold me-2">Invitee:</span>
                  <span class="text-black">{{ meeting.inviteeName }}</span>
               </p>
               <p class="mb-2">
                  <span class="fw-bold me-2">Internal Participants:</span>
                  <template
                     v-for="(
                        internalParticipant, index
                     ) in meeting.intenalParticipants"
                     :key="index"
                  >
                     <span class="text-black"
                        >{{ internalParticipant.name }},
                     </span>
                  </template>
               </p>
               <p class="mb-2">
                  <span class="fw-bold me-2">External Participants:</span>
                  <span class="text-black">{{
                     meeting.externalParticipants
                  }}</span>
               </p>
               <p class="mb-0">
                  <span class="fw-bold me-2">Status:</span>
                  <span class="text-black">{{ meeting.status }}</span>
               </p>
            </li>
         </ul>
      </template>

      <template v-slot:footer>
         <button type="button" class="btn btn-light" data-bs-dismiss="modal">
            Close
         </button>
      </template>
   </MeetingDetailsModal>
</template>

<script>
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { createEventId } from "../../utlis/event-utlis";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

import MeetingDetailsModal from "../../components/shared/Modal.vue";
import axios from "axios";
import $ from "jquery";
import moment from "moment";
import { mapActions } from "vuex";

export default {
   components: {
      FullCalendar,
      MeetingDetailsModal,
      vSelect,
   },
   data() {
      return {
         loading: false,
         rooms: [],
         selected: {
            roomId: "all",
            intParticipantsId: [],
         },
         meetings: [],
         intParticipants: [],
         meeting: {
            title: null,
            roomName: null,
            projects: [],
            inviteeName: null,
            start: null,
            end: null,
            intenalParticipants: [],
            externalParticipants: null,
            status: "",
         },
         calendarOptions: {
            plugins: [
               dayGridPlugin,
               timeGridPlugin,
               interactionPlugin, // needed for dateClick
            ],
            headerToolbar: {
               left: "prev,next today",
               center: "title",
               right: "dayGridMonth,timeGridWeek,timeGridDay",
            },
            initialView: "dayGridMonth",
            events: null, // alternatively, use the `events` setting to fetch from a feed
            editable: false,
            selectable: false,
            selectMirror: true,
            dayMaxEvents: true,
            weekends: true,
            select: this.handleDateSelect,
            eventClick: this.handleEventClick,
            eventsSet: this.handleEvents,
            height: "auto",
            /* you can update a remote database when these fire:
            eventAdd:
            eventChange:
            eventRemove:
            */
         },
         currentEvents: [],
         baseUrl: process.env.VUE_APP_BASE_URL,
      };
   },
   methods: {
      ...mapActions(["auth/logout"]),
      async fethAllMeetingSchedules(loading = true) {
         this.loading = loading;
         await axios
            .get(`${this.baseUrl}frontend/v1/meetings`)
            .then((result) => {
               // console.log(result.data.data);
               this.calendarOptions.events = result.data.data;
               this.meetings = result.data.data;
            });
         this.loading = false;
      },
      handleWeekendsToggle() {
         this.calendarOptions.weekends = !this.calendarOptions.weekends; // update a property
      },
      handleDateSelect(selectInfo) {
         let title = prompt("Please enter a new title for your event");
         let calendarApi = selectInfo.view.calendar;

         calendarApi.unselect(); // clear date selection

         if (title) {
            calendarApi.addEvent({
               id: createEventId(),
               title,
               start: selectInfo.startStr,
               end: selectInfo.endStr,
               allDay: selectInfo.allDay,
            });
         }
      },
      async handleEventClick(clickInfo) {
         await axios
            .get(`${this.baseUrl}frontend/v1/meetings/${clickInfo.event.id}`)
            .then((response) => {
               const result = response.data.data;

               this.meeting.title = result.title;
               this.meeting.roomName = result.room;
               this.meeting.inviteeName = result.invitee;
               this.meeting.start = result.start;
               this.meeting.end = result.end;
               this.meeting.intenalParticipants = result.internal_participants;
               this.meeting.externalParticipants = result.external_participants;
               this.meeting.projects = result.projects;
               this.meeting.status = result.status;
               $("#meeting-details").modal("show");
            });
      },
      handleEvents(events) {
         this.currentEvents = events;
      },
      getRooms() {
         axios
            .get(`${this.baseUrl}frontend/v1/rooms`)
            .then((response) => {
               this.rooms = response.data.data;
            })
            .catch((error) => {
               this.errors = error.response.data.errors;
               console.log(this.errors);
            });
      },
      filterMeeting() {
         var result = this.meetings;
         //filter by room
         if (this.selected.roomId !== "all") {
            result = this.meetings.filter(
               (meeting) => meeting.room_id == this.selected.roomId
            );
         }
         //filter by employee
         if (this.selected.intParticipantsId.length !== 0) {
            result = result.filter((meeting) =>
               this.getArraysIntersection(
                  meeting.user_ids,
                  this.selected.intParticipantsId
               )
            );
         }
         this.calendarOptions.events = result;
      },
      getArraysIntersection(a1, a2) {
         var arr = [];
         arr = a1.filter(function (n) {
            return a2.indexOf(n) !== -1;
         });

         return arr.length > 0 ? true : false;
      },
      getIntParticipants() {
         axios.get(`${this.baseUrl}frontend/v1/users`).then((response) => {
            this.intParticipants = response.data.data;
         });
      },
      refreshData() {
         this.fethAllMeetingSchedules();
      },
      logout() {
         this["auth/logout"]()
            .then(() => {
               this.$router.push({ name: "login" });
            })
            .catch(() => {
               console.log("error");
               this.$store.dispatch("auth/logout", this.token);
               this.$router.push({ name: "login" });
            });
      },
   },
   created() {
      this.fethAllMeetingSchedules();
      this.getRooms();
      this.getIntParticipants();
      // setInterval(() => {
      //    this.fethAllMeetingSchedules(false);
      // }, 1000);
   },
   computed: {
      meetingDate() {
         const startDate = new Date(this.meeting.start);
         const endDate = new Date(this.meeting.end);
         return (
            moment(startDate).format("DD-MMM-YYYY [(] h:mm A ") +
            "to" +
            moment(endDate).format(" h:mm A [)]")
         );
      },
   },
   mounted() {
      document.body.classList.add(
         "authentication-bg",
         "authentication-bg-pattern"
      );
   },
   unmounted() {
      document.body.classList.remove(
         "authentication-bg",
         "authentication-bg-pattern"
      );
   },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
   transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
   opacity: 0;
}

.custom-select {
   padding: 0.28rem 0.9rem;
}
</style>